require('whatwg-fetch');
const React = require('react');
const useSWR = require('swr');
const { RecoilRoot } = require('recoil');
const { QueryParamProvider } = require('use-query-params');
const { globalHistory } = require('@reach/router');
const { withLDProvider } = require('launchdarkly-react-client-sdk');

const { AuthProvider } = require('./src/contexts/AuthContext');
const { ErrorProvider } = require('./src/contexts/ErrorContext');
const FeatureProvider = require('./src/contexts/FeatureContext').default;
const ErrorBoundary = require('./src/components/ErrorBoundary').default;
const { fetcher } = require('./src/utilities/fetcher');
const api = require('./src/utilities/api').default;

require('./src/styles/global.css');

const SWRConfig = useSWR.SWRConfig;

var scoreEnvId;

exports.onClientEntry = async () => {
  scoreEnvId = process.env.GATSBY_LAUNCHDARKLY_CLIENT_KEY;
  if (!scoreEnvId) {
    const {
      launchDarkly: { scoreEnvId: loadedEnvId },
    } = await api.loadConfiguration();
    scoreEnvId = loadedEnvId;
  }
};

exports.wrapRootElement = ({ element /*, children */ }) => {
  const LDProvider = withLDProvider({
    clientSideID: scoreEnvId,
  })(FeatureProvider);

  return (
    <SWRConfig value={{ fetcher }}>
      <QueryParamProvider path="/" reachHistory={globalHistory}>
        <RecoilRoot>
          <ErrorBoundary>
            <ErrorProvider>
              <LDProvider>
                <AuthProvider>{element}</AuthProvider>
              </LDProvider>
            </ErrorProvider>
          </ErrorBoundary>
        </RecoilRoot>
      </QueryParamProvider>
    </SWRConfig>
  );
};
