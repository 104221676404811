import React, { useContext, Fragment } from 'react';
import { navigate } from '@reach/router';
import classNames from 'classnames/bind';
import { useRecoilValue } from 'recoil';

import { themeLogin, showLogin, showLogo } from '../../../state/theme';
import { Button } from '../Button';
import { AuthContext } from '../../../contexts/AuthContext';
import User from '../../../images/svg/user.svg';
import AngleDown from '../../../images/svg/angle-down.svg';
import login from '../../../images/login.svg';
import login_light from '../../../images/login_light.svg';
import { Popover } from '../Popover';
import { Logo } from '../Logo';
import { useGTMEvent } from '../../../hooks/useGTMEvent';

const Account = ({ user, isLightTheme }) => {
  const { firstName, surname } = user;
  const fullName = `${firstName} ${surname}`;

  const iconCss = classNames('mr-2 h-6', {
    'fill-white': isLightTheme,
    'fill-secondary': !isLightTheme,
  });

  const fullNameCss = classNames('mr-2', {
    'text-white': isLightTheme,
    'text-secondary': !isLightTheme,
  });

  return (
    <Fragment>
      <User className={iconCss} />
      <span className={fullNameCss}>{fullName}</span>
      <AngleDown className={iconCss} />
    </Fragment>
  );
};

export const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const loginVariant = useRecoilValue(themeLogin);
  const showLoginButton = useRecoilValue(showLogin);
  const isLogoVisible = useRecoilValue(showLogo);

  const isLoginLightTheme = loginVariant === 'light';

  const loginClassName = classNames('flex items-center text-secondary', {
    'text-white': isLoginLightTheme,
  });

  const containerCss = classNames('flex items-center max-w-screen-xl mx-auto', {
    'justify-between': isLogoVisible,
    'justify-end': !isLogoVisible,
  });

  const userIconSrc = isLoginLightTheme ? login_light : login;

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const pushGTMEvent = useGTMEvent({
    event: 'homepage:login',
    eventCategory: 'homepage',
    eventAction: 'click',
    eventLabel: 'homepage_login',
  });

  return (
    <header
      className="absolute right-0 left-0 top-0 px-4 py-8 z-30"
      data-testid="menu"
    >
      <div className={containerCss}>
        {isLogoVisible && <Logo />}

        {user && (
          <Popover
            className="flex items-center py-2"
            title={<Account user={user} isLightTheme={isLoginLightTheme} />}
            data-testid="account"
          >
            <button onClick={handleLogout} data-testid="login-button">
              Odhlásit se
            </button>
          </Popover>
        )}
        {!user && showLoginButton && (
          <Button
            color="transparent"
            border="thin"
            to="/ucet/prihlaseni"
            className={loginClassName}
            data-testid="link-to-login-page"
            onClick={pushGTMEvent}
          >
            <img
              className="mr-2"
              src={userIconSrc}
              alt="Ikona - užívatel"
              aria-hidden="true"
            />
            <span>Přihlásit se</span>
          </Button>
        )}
      </div>
    </header>
  );
};
