import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSetRecoilState } from 'recoil';

import {
  themeLogo,
  themeLogin,
  themeFooter,
  showLogin,
  showLogo,
} from '../../../state/theme';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { DevNav } from './DevNav';
import { Aside } from './Aside';

import './Layout.css';

export const Layout = ({
  theme,
  children,
  logoVariant,
  loginVariant,
  footerVariant,
  asideContent,
  showLoginButton,
  noVerticalPadding,
}) => {
  const setLogo = useSetRecoilState(themeLogo);
  const setLogin = useSetRecoilState(themeLogin);
  const setFooter = useSetRecoilState(themeFooter);
  const setShowLogin = useSetRecoilState(showLogin);
  const setShowLogo = useSetRecoilState(showLogo);

  useEffect(() => {
    setLogo(logoVariant);
  }, [logoVariant, setLogo]);

  useEffect(() => {
    setLogin(loginVariant);
  }, [loginVariant, setLogin]);

  useEffect(() => {
    setFooter(footerVariant);
  }, [footerVariant, setFooter]);

  useEffect(() => {
    setShowLogin(showLoginButton);
  }, [showLoginButton, setShowLogin]);

  useEffect(() => {
    const isVisible = !!asideContent;

    setShowLogo(!isVisible);
  }, [asideContent, setShowLogo]);

  const mainClassName = classNames('flex-1 relative', {
    'px-4 py-40': !noVerticalPadding,
  });

  const containerCss = classNames(
    'flex my-0 mx-auto min-h-screen relative',
    theme
  );

  const isInDevelopment = process.env.NODE_ENV === 'development';

  return (
    <Fragment>
      {isInDevelopment && <DevNav />}
      <div className={containerCss}>
        <Header />
        {asideContent && <Aside children={asideContent} />}
        <main className={mainClassName}>
          {children}
          <Footer />
        </main>
      </div>
    </Fragment>
  );
};

const themeVariants = PropTypes.oneOf(['light', 'dark']);

Layout.propTypes = {
  theme: PropTypes.string,
  noVerticalPadding: PropTypes.bool,
  logoVariant: themeVariants,
  loginVariant: themeVariants,
  footerVariant: themeVariants,
  showLoginButton: PropTypes.bool,
  asideContent: PropTypes.element,
  children: PropTypes.node.isRequired,
};
