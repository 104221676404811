import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const Popover = ({ title, children, className, ...rest }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <div
      onMouseEnter={toggleOpen}
      onMouseLeave={toggleOpen}
      className={`relative cursor-default ${className}`}
      {...rest}
    >
      {title}
      {open && (
        <div className="absolute rounded top-100 left-0 right-0 bg-white p-4 border-grey-2 shadow-md fade-in">
          {children}
        </div>
      )}
    </div>
  );
};

Popover.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};
