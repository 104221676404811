import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export const Button = ({
  children,
  className,
  disabled,
  href,
  target,
  to,
  color,
  variant,
  border,
  size,
  ...rest
}) => {
  const isPrimary = color === 'primary';
  const isSecondary = color === 'secondary';
  const hasBorder = !!border;

  const css = classNames(
    'inline-block text-center select-none tracking-widest transition-colors duration-200 ease-out focus:outline-none',
    className,
    {
      'text-white bg-primary': isPrimary,
      'hover:bg-primary hover:shadow-button-primary': isPrimary && !disabled,
      'text-secondary bg-white': isSecondary,
      'hover:bg-secondary hover:text-white hover:shadow-button-secondary':
        isSecondary && !disabled,
      'font-bold uppercase': isPrimary || isSecondary,
      'bg-transparent': color === 'transparent',
      'rounded-full': variant === 'rounded',
      'py-1 px-2 sm:py-2 sm:px-3 text-xs': size === 'small',
      'py-3 px-4 sm:py-4 sm:px-6 text-base': size === 'normal',
      'border-style': hasBorder,
      'border-3': border === 'normal',
      'border-2': border === 'thin',
      'border-white': hasBorder && !isPrimary && !isSecondary,
      'border-primary': hasBorder && isPrimary,
      'border-secondary': hasBorder && isSecondary,
      'text-white bg-grey-2 border-grey-2 cursor-not-allowed': disabled,
    }
  );

  const tabIndex = disabled ? -1 : 0;

  if (href) {
    const rel = target && 'noopener noreferrer';

    return (
      <a
        href={href}
        className={css}
        tabIndex={tabIndex}
        target={target}
        rel={rel}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={css} tabIndex={tabIndex} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <button className={css} tabIndex={tabIndex} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  color: 'primary',
  variant: 'rounded',
  size: 'normal',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'transparent']),
  variant: PropTypes.oneOf([null, 'rounded']),
  border: PropTypes.oneOf(['thin', 'normal']),
  size: PropTypes.oneOf(['small', 'normal']),
};
