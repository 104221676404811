import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from '../Logo';

export const Aside = ({ children }) => {
  return (
    <aside
      className="w-1/4 bg-secondary text-white menu-aside z-30"
      data-testid="aside"
    >
      <Logo className="py-8 text-center" />
      {children}
    </aside>
  );
};

Aside.propTypes = {
  children: PropTypes.node.isRequired,
};
