import { Link } from 'gatsby';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useRecoilValue } from 'recoil';

import { themeLogo } from '../../../state/theme';
import { AuthContext } from '../../../contexts/AuthContext';

export const Logo = ({ className }) => {
  const { user } = useContext(AuthContext);
  const logoVariant = useRecoilValue(themeLogo);

  const isLogoLightTheme = logoVariant === 'light';

  const osobniClassName = classNames(
    'mr-1 sm:text-2xl uppercase font-extrabold tracking-widest',
    {
      'text-primary': isLogoLightTheme,
      'text-secondary': !isLogoLightTheme,
    }
  );

  const skoreClassName = classNames(
    'sm:text-2xl uppercase tracking-widest font-light',
    {
      'text-white': isLogoLightTheme,
      'text-primary': !isLogoLightTheme,
    }
  );

  return (
    <h1 className={className}>
      <Link to={user ? '/moje/nastenka' : '/'} data-testid="logo">
        <span className={osobniClassName}>osobní</span>
        <span className={skoreClassName}>skóre</span>
      </Link>
    </h1>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};
