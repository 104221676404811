import React, { useState, useEffect, Fragment } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const FeatureProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient) {
      setIsLoaded(true);
    }
  }, [ldClient, setIsLoaded]);

  return <Fragment>{isLoaded ? children : null}</Fragment>;
};

export default FeatureProvider;
