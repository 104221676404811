import React, { useEffect, useState, useCallback } from 'react';
import { useSWRConfig } from 'swr';
import { useCookies } from 'react-cookie';
import { navigate } from '@reach/router';

import Api from '../utilities/api';
import { setToken } from '../utilities/fetchJson';

const ACCESS_TOKEN = 'zscore-access-token';
const SMS_TOKEN = 'zscore-sms-token';
const WELCOME = 'zscore-welcome-drink';

const defaultState = {
  isLoading: true,
  user: null,
};

export const AuthContext = React.createContext(defaultState);

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const [cookies, setCookie, removeCookie] = useCookies([
    ACCESS_TOKEN,
    SMS_TOKEN,
    WELCOME,
  ]);
  const { mutate } = useSWRConfig();

  const accessToken = cookies[ACCESS_TOKEN];
  const smsToken = cookies[SMS_TOKEN];
  const welcomeCookie = cookies[WELCOME];

  const login = async data => {
    setState({ isLoading: true });

    const { access_token } = await Api.login(data);

    setCookie(ACCESS_TOKEN, access_token, { path: '/' });
  };

  const logout = useCallback(() => {
    setState({ ...defaultState, isLoading: false });

    removeCookie(ACCESS_TOKEN, { path: '/' });
    removeCookie(SMS_TOKEN, { path: '/' });
    mutate(() => true, undefined, false); // clearing SWR cache
  }, [removeCookie, mutate]);

  const register = async data => {
    const token = await Api.register(data);

    setCookie(SMS_TOKEN, token, { path: '/' });

    navigate('/ucet/sms-kod');
  };

  const verify = async ({ pin }) => {
    const data = {
      pin,
      sms_token: smsToken,
    };

    setState({ isLoading: true });
    const { access_token } = await Api.verify(data);

    setCookie(ACCESS_TOKEN, access_token, { path: '/' });
    setCookie(WELCOME, 'true', { path: '/' });
  };

  const resendAuthSMS = async () => {
    const data = {
      smsToken,
    };

    setState({ isLoading: true });

    const result = await Api.resendAuthSMS(data);

    setCookie(SMS_TOKEN, result.smsToken, { path: '/' });

    return result;
  };

  const generateResetLink = async data => {
    await Api.generateResetLink(data);
  };

  const newPassword = async data => {
    await Api.newPassword(data);
  };

  const removeWelcomeCookie = () => {
    removeCookie(WELCOME, { path: '/' });
  };

  const hasSMSToken = () => {
    return !!smsToken;
  };

  const setUser = useCallback(user => {
    setState({ isLoading: false, user });
  }, []);

  const loadAndSetUser = useCallback(async () => {
    let user = null;

    if (accessToken) {
      try {
        user = await Api.loadSelf();
      } catch (error) {
        return logout();
      }
    }

    setUser(user);
  }, [accessToken, logout, setUser]);

  useEffect(() => {
    setToken(accessToken);
    loadAndSetUser();
  }, [accessToken, loadAndSetUser]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        register,
        login,
        setUser,
        logout,
        verify,
        loadAndSetUser,
        resendAuthSMS,
        generateResetLink,
        newPassword,
        welcomeCookie,
        removeWelcomeCookie,
        hasSMSToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
