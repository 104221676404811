import { fetchJson } from './fetchJson';
import Registers from '../Registers';

class Api {
  async loadConfiguration() {
    const response = await fetchJson('GET', '/api/configuration/fe');
    return await response.json();
  }

  async login({ email, ...data }) {
    const response = await fetchJson('POST', '/api/oauth/token', {
      headers: {
        Authorization: 'Basic d2ViOndlYg==',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        ...data,
        username: email,
        grant_type: 'password',
        scope: 'SCOPE_ZSCORE_WEB',
      },
    });

    return await response.json();
  }

  async register(body) {
    const response = await fetchJson('POST', '/api/auth/registration-tokens', {
      headers: { Authorization: 'Basic d2ViOndlYg==' },
      body,
    });

    const { token } = await response.json();

    return token;
  }

  async verify(data) {
    const response = await fetchJson('POST', '/api/oauth/token', {
      headers: {
        Authorization: 'Basic d2ViOndlYg==',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        client_id: 'web',
        client_secret: 'web',
        grant_type: 'sms_token',
        scope: 'SCOPE_ZSCORE_WEB',
        ...data,
      },
    });

    return await response.json();
  }

  async resendAuthSMS(body) {
    const response = await fetchJson(
      'POST',
      '/api/auth/registration-tokens/resend',
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    );

    return await response.json();
  }

  generateResetLink(body) {
    return fetchJson('POST', `/api/users/reset-password`, {
      body,
    });
  }

  newPassword(body) {
    return fetchJson('PUT', `/api/users/password`, {
      body,
    });
  }

  async createSmsToken(phone) {
    const response = await fetchJson('POST', `/api/auth/sms-tokens`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `phone=${encodeURIComponent(phone)}`,
    });

    return await response.json();
  }

  async saveUser(userId, user) {
    const response = await fetchJson('PATCH', `/api/users/${userId}`, {
      body: JSON.stringify(user),
    });
    return await response.json();
  }

  async loadSelf() {
    const response = await fetchJson('GET', '/api/users/me');
    return await response.json();
  }

  async loadApplications() {
    const response = await fetchJson('GET', '/api/users/me/applications');
    return await response.json();
  }

  async createApplication() {
    const response = await fetchJson('POST', '/api/users/me/applications');
    return await response.json();
  }

  async closeApplication(applicationId) {
    const response = await fetchJson(
      'PUT',
      `/api/applications/${applicationId}/finish`
    );
    return response;
  }

  async loadMaritalStatusOptions() {
    return await new Promise(resolve => resolve(Registers.maritalStatus));
  }

  async loadEducationOptions() {
    const response = await fetchJson('GET', '/api/registers/7');
    return await response.json();
  }

  async loadHousingTypeOptions() {
    return await new Promise(resolve => resolve(Registers.housingType));
  }

  async loadSocdemData(socdemDataId) {
    const response = await fetchJson(
      'GET',
      `/api/soc-dem-data/${socdemDataId}`
    );
    return await response.json();
  }

  async createSocdemData(openApplicationId, body = {}) {
    const response = await fetchJson(
      'POST',
      `/api/applications/${openApplicationId}/soc-dem-data`,
      {
        body,
      }
    );
    return await response.json();
  }

  async saveSocdemData(body, id) {
    const response = await fetchJson('PATCH', `/api/soc-dem-data/${id}`, {
      body,
    });
    return await response.json();
  }

  async loadAttachments(applicationId) {
    const response = await fetchJson(
      'GET',
      `/api/applications/${applicationId}/attachments`
    );
    return await response.json();
  }

  async createAttachment(applicationId, formData) {
    const response = await fetchJson(
      'POST',
      `/api/applications/${applicationId}/attachments`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
      }
    );
    return await response.json();
  }

  async deleteAttachment(attachmentId) {
    const response = await fetchJson(
      'DELETE',
      `/api/applications/attachments/${attachmentId}`
    );
    return response;
  }

  async loadScore(applicationId) {
    const response = await fetchJson(
      'GET',
      `/api/applications/${applicationId}/score`
    );
    return await response.json();
  }

  async updateUser(body) {
    const response = await fetchJson('PATCH', '/api/users/me', {
      body,
    });

    return await response.json();
  }

  async loadInsolvence() {
    const response = await fetchJson('POST', '/api/isir-register');

    return await response.json();
  }
}

const api = new Api();

export default api;
