export default {
  housingType: {
    regNumber: 9,
    name: 'Forma bydlení',
    registerItems: [
      {
        code: '1',
        name: 'V nájmu',
        status: 'ACTIVE',
      },
      {
        code: '2',
        name: 'Ve vlastním s hypotékou',
        status: 'ACTIVE',
      },
      {
        code: '6',
        name: 'U partnera / partnerky',
        status: 'ACTIVE',
      },
      {
        code: '4',
        name: 'V družstevním bytě',
        status: 'ACTIVE',
      },
      {
        code: '3',
        name: 'Ve vlastním bez hypotéky',
        status: 'ACTIVE',
      },
      {
        code: '5',
        name: 'U rodiny',
        status: 'ACTIVE',
      },
    ],
  },
  maritalStatus: {
    regNumber: 8,
    name: 'Rodinný stav',
    registerItems: [
      {
        code: '1',
        name: 'Vdaná / ženatý',
        status: 'ACTIVE',
      },
      {
        code: '2',
        name: 'Svobodná / svobodný',
        status: 'ACTIVE',
      },
      {
        code: '3',
        name: 'Žiji s partnerkou / partnerem',
        status: 'ACTIVE',
      },
      {
        code: '5',
        name: 'Registrované partnerství',
        status: 'ACTIVE',
      },
      {
        code: '4',
        name: 'Rozvedená / rozvedený',
        status: 'ACTIVE',
      },
      {
        code: '6',
        name: 'Vdova / vdovec',
        status: 'ACTIVE',
      },
    ],
  },
};
