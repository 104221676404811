import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../../components/shared/Button';

import styles from './ErrorPage.module.css';

const cx = classNames.bind(styles);

export const ErrorPage = ({
  className,
  seoTitle,
  title,
  description,
  onClick,
}) => {
  const containerCss = cx(className, { [styles.wrapper]: !className });

  return (
    <Layout>
      <Seo title={seoTitle} />
      <div className={containerCss}>
        <h1 className={styles.title} data-testid="error-page-title">
          {title}
        </h1>
        <h2 className={styles.description} data-testid="error-page-description">
          {description}
        </h2>

        <Button
          to="/"
          onClick={onClick}
          className="mx-auto"
          data-testid="error-page-osobniskore-button"
        >
          Přejít na osobniskore.cz
        </Button>
      </div>
    </Layout>
  );
};

ErrorPage.propTypes = {
  className: PropTypes.string,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};
