import format from 'date-fns/format';
import { fetchData } from './fetchData';

let token = null;

export function setToken(newToken) {
  token = newToken;
}

export class FetchError extends Error {
  constructor(request, response, options = {}) {
    super(`${request.method} ${request.url} failed`);

    this.name = 'FetchError';
    this.request = request;
    this.response = response;
    this.options = options;
  }

  get isBadRequest() {
    return this.response.status === 400;
  }

  get isUnauthorized() {
    const { status } = this.response;
    const isUnauthorized = status === 401;

    return isUnauthorized;
  }

  get isForbidden() {
    return this.response.status === 403;
  }

  get isNotFound() {
    return this.response.status === 404;
  }

  get isConflict() {
    return this.response.status === 409;
  }

  get isInternalServerError() {
    return this.response.status === 500;
  }

  get isServiceUnavailable() {
    return this.response.status === 503;
  }

  get humanFriendlyMessage() {
    let message = 'Něco se pokazilo';

    if (this.isBadRequest) {
      message = 'Nesprávny dotaz';
    } else if (this.isUnauthorized) {
      message = 'Neautorizovaný přístup';
    } else if (this.isNotFound) {
      message = 'Zdroj neexistuje';
    } else if (this.isInternalServerError) {
      message = 'Vyskytla se vnitřní chyba systému';
    } else if (this.isServiceUnavailable) {
      message = 'Systém je nedostupný';
    }

    return message;
  }
}

export class PhoneNotWhitelistedError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isPhoneNotWhitelisted() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Službu zatím testujeme jen s pár uživateli. Velmi brzy bude přístupná i pro Vás.';
  }
}

export class StatementsUnparseableError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isStatementsUnparseable() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Při zpracování Vašich výpisů došlo k chybě. Bohužel neumíme zpracovat výpisy jiných bank než uvedených na ';
  }
}

export class StatementsMissingMandatoryTransactionDataError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isStatementsEmpty() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Výpis neobsahuje žádné transakce, zkuste jiný.';
  }
}

export class InvalidSmsCodeError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isInvalidSmsCode() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Zadali jste nesprávný kód.';
  }
}

export class SmsLimitExceededError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isSmsLimitExceeded() {
    return true;
  }

  get requestPossibleAt() {
    return this.response.headers.get('X-Request-Possible-At');
  }

  get humanFriendlyMessage() {
    const possibleAtFormatted = format(
      new Date(this.requestPossibleAt),
      'HH:mm'
    );
    return `Maximální počet pokusů pro zadání SMS kódu překročen. Opakujte nejdřív v&nbsp;${possibleAtFormatted}.`;
  }
}

export class SMSTokenExpired extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isSmsTokenExpired() {
    return true;
  }

  get humanFriendlyMessage() {
    return `Vypršela platnost SMS kódu.`;
  }
}

export class UnprocessableEntityError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isUnprocessableEntity() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Výpisy z PDF nelze zpracovat';
  }
}

export class BriefHistoryOfStatementsError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isBriefHistoryOfStatements() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Transakční historie je příliš krátká, požadované minimum je 3 měsíce';
  }
}

export class LowNumberOfTransactionsError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isLowNumberOfTransactions() {
    return true;
  }

  get humanFriendlyMessage() {
    return 'Máte příliš málo transakcí na výpočet relevantního skóre';
  }
}

export class MissingStatementInRowError extends FetchError {
  constructor({ request, response, options }) {
    super(request, response, options);
  }

  get isMissingStatementInRow() {
    return true;
  }
  get humanFriendlyMessage() {
    return 'Vaše výpisy nejsou za tři po sobě jdoucí měsíce';
  }
}

async function createFetchError(request, response) {
  let options;

  try {
    options = await response.json();
  } catch (_) {
    // use default options
  }

  const error = new FetchError(request, response, options);

  if (error.isBadRequest) {
    if (error.options.error === 'TOKEN_EXPIRED') {
      return new SMSTokenExpired({ request, response, options });
    }
    if (error.options.error === 'BANK_STATEMENT_PARSING_FAILED') {
      return new StatementsUnparseableError({ request, response, options });
    }
    if (error.options.error === 'MISSING_MANDATORY_TRANSACTION_DATA') {
      return new StatementsMissingMandatoryTransactionDataError({
        request,
        response,
        options,
      });
    }
    if (error.options.error_code === 'BAD_CREDENTIALS') {
      return new InvalidSmsCodeError({ request, response, options });
    }
    if (error.options.error === 'UNPROCESSABLE_ENTITY') {
      return new UnprocessableEntityError({ request, response, options });
    }
    if (error.options.error === 'BRIEF_HISTORY_OF_STATEMENTS') {
      return new BriefHistoryOfStatementsError({ request, response, options });
    }
    if (error.options.error === 'LOW_NUMBER_OF_TRANSACTIONS') {
      return new LowNumberOfTransactionsError({ request, response, options });
    }
    if (error.options.error === 'MISSING_STATEMENT_IN_ROW') {
      return new MissingStatementInRowError({ request, response, options });
    }
  }

  if (error.isForbidden) {
    if (error.options.error === 'PHONE_NOT_WHITE_LISTED') {
      return new PhoneNotWhitelistedError({ request, response, options });
    }
    if (error.options.error === 'SMS_LIMIT_EXCEEDED') {
      return new SmsLimitExceededError({ request, response, options });
    }
  }

  return error;
}

export async function fetchJson(method, url, options = {}) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  if (headers['Content-Type'] === 'multipart/form-data') {
    // browser requires no content-type header be set in case of 'multipart/form-data'
    delete headers['Content-Type'];
  }

  if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    options.body = Object.keys(options.body)
      .map(key => key + '=' + encodeURIComponent(options.body[key]))
      .join('&');
  }

  if (headers['Content-Type'] === 'application/json') {
    options.body = JSON.stringify(options.body);
  }

  const response = await fetchData(url, {
    method,
    ...options,
    headers,
  });

  if (response.ok) {
    return response;
  }

  const error = await createFetchError({ method, url }, response);

  throw error;
}
